import AuthServices from "@/services/AuthServices";

const state = () => ({
  cashier: {}
})

const mutations = {
  ACTIVE_CASHIER(state, payload){
    state.cashier = payload
    localStorage.activeCashier = JSON.stringify(payload)
  }
}

const actions = {
  loginCashier({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AuthServices.loginCashier(payload).then(response => {
        if (response.data.status_code === 200) {
          localStorage.setItem("access-token", response.data.data.access_token);
          commit("ACTIVE_CASHIER", response.data.data.user)
        }
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}