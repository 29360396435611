import { apiClientAuth } from "@/config";


export default {
  async getPaymentMethodList(payload) {
    return apiClientAuth({
      method: "get",
      url: "/api/cashier/payments/method-types",
      ...payload
    });
  },
  async setPaymentUsePoint (payload) {
    return apiClientAuth({
      method: "get",
      url: "/api/cashier/carts/point-cost/" + payload
    })
  }
}