import { apiClientAuth } from "@/config";

export default {
  async getCategories(payload) {
    return apiClientAuth({
      method: "get",
      url: "/api/categories",
      ...payload
    });
  },
}