import orderServices from "@/services/OrderServices";

const state = () => ({
  order: localStorage.order ? JSON.parse(localStorage.getItem('order')) : {}
})

const mutations = {
  SET_ORDER(state, payload){
    state.order = payload
    localStorage.setItem('order', JSON.stringify(payload))

  }
}

const actions = {
  createOrder ({commit}, payload) {
    return new Promise((resolve, reject) => {
    orderServices.createOrder(payload).then(response => {
        if (response.data.status_code === 201) {
          commit("SET_ORDER",response.data.data.order)
        }
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}