import ReturnServices from "@/services/ReturnServices";

const state = () => ({
  returnData: {},
  selectedProduct: [],
  returnedOrder: localStorage.returnedData ? JSON.parse(localStorage.getItem('returnedData')) : {}
})

const mutations = {
  SET_RETURN_DATA(state, payload) {
    let returnPayload = payload
    if (!returnPayload.error) {
      returnPayload.products = returnPayload.products.map(product => {
        return {
          ...product,
          quantity_return: 1,
          return_reason: ''
        }
      })
    }
    state.returnData = returnPayload
  },
  SET_RETURNED_DATA(state, payload) {
    state.returnedOrder = payload
    state.returnData = {}
    localStorage.setItem('returnedData', JSON.stringify(payload))
  }
}

const actions = {
  verifyAccessCode(_, payload) {
    return new Promise((resolve, reject) => {
      ReturnServices.verifyAccessCode(payload)
        .then(response => {

          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  searchReturnOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ReturnServices.searchReturnOrder(payload)
        .then(response => {
          if (response.data.status_code === 200) {
            commit("SET_RETURN_DATA", response.data.data.order);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  returnProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ReturnServices.returnOrder(payload)
        .then(response => {
          if (response.data.status_code === 201) {
            commit("SET_RETURNED_DATA", response.data.data.return_transaction);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}