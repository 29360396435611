import ShiftServices from "@/services/ShiftServices";

const state = () => ({
 shift: localStorage.getItem('active-shift') ?  JSON.parse(localStorage.getItem('active-shift')) : '',
 shiftId: localStorage.getItem('active-shift-id') ?  localStorage.getItem('active-shift-id') : '',
 isOnShift: localStorage.getItem('active-shift') ? true : false,
 shiftTransaction: {
  data: []
 },
 shiftTransactionAll: [],
 selectedShiftTransaction :  localStorage.getItem('selected-shift-transaction') ? JSON.parse(localStorage.getItem('selected-shift-transaction')) : {},
 shiftTransactionLoading: true
})

const mutations = {
    SET_CASHIER_SHIFT (state, payload) {
        state.shift = payload
        localStorage.setItem('active-shift-id', payload.id)
        localStorage.setItem('active-shift', JSON.stringify(payload))
        state.shiftId = payload.id
        state.isOnShift = true
    },
    CLEAR_CASHIER_SHIFT(state ) {
      state.shift = ''
      state.shiftId = ''
      state.isOnShift = false
      localStorage.removeItem('active-shift-id')
      localStorage.removeItem('active-shift')
    },
    END_SHIFT (state) {
      localStorage.removeItem('active-shift-id')
      localStorage.removeItem('active-shift')
      state.shift = {}
      state.shiftId = ''
      state.isOnShift = false

    },
    SET_SHIFT_TRANSACTION (state, payload) {
      state.shiftTransaction = payload
    },
    SET_SHIFT_TRANSACTION_ALL (state, payload) {
      state.shiftTransactionAll = payload
    },
    ADD_SHIFT_TRANSACTION (state, payload) {
      state.shiftTransaction.data.push(...payload)
    },
    SET_SELECTED_SHIFT_TRANSACTION (state, payload) {
      localStorage.setItem('selected-shift-transaction', JSON.stringify(payload))
      state.selectedShiftTransaction = payload
    },
    SET_SHIFT_TRANSACTION_LOADING (state, payload) {
      state.shiftTransactionLoading = payload
    }
}

const actions = {
  startShift({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ShiftServices.startShift(payload)
      .then(response => {
        if (response.data.status_code === 201) {
          commit("SET_CASHIER_SHIFT", response.data.data.shift);
        }
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  getShift ({commit}) {
    return new Promise((resolve, reject) => {
      ShiftServices.getShift()
      .then(response => {
        if (response.data.status_code === 200) {
          commit("SET_CASHIER_SHIFT", response.data.data.shift);
        }
        resolve(response);
      })
      .catch(error => {
        if(error.response.data.status_code === 404) {
          commit("CLEAR_CASHIER_SHIFT")
        } else {
          reject(error);
        }
      });
    });
  },
  endShift({commit}, payload) {
    return new Promise((resolve, reject) => {
      ShiftServices.endShift(payload)
      .then(response => {
          if(response.data.status_code === 200) {
            commit("END_SHIFT")
          }
        // if (response.data.status_code === 200) {
        //   commit("SET_CASHIER_SHIFT", response.data.data.);
        // }
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  createShiftTransaction ( _ , payload) {
    return new Promise((resolve, reject) => {
      ShiftServices.createShiftTransaction(payload)
      .then(response => {
        // commit("SET_SHIFT_TRANSACTION_LOADING", true)

        // if (response.data.status_code === 200) {
        //   commit("SET_SHIFT_TRANSACTION", response.data.data.shift_transactions);
        //   commit("SET_SHIFT_TRANSACTION_LOADING", false)
        // }
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  getShiftListTransaction ( {commit} , payload) {
    return new Promise((resolve, reject) => {
      ShiftServices.getShiftListTransaction(payload)
      .then(response => {
        commit("SET_SHIFT_TRANSACTION_LOADING", true)
        if (response.data.status_code === 200 && response.data.data.shift_transactions.current_page === 1) {
          commit("SET_SHIFT_TRANSACTION", response.data.data.shift_transactions);
        } else {
          commit("ADD_SHIFT_TRANSACTION", response.data.data.shift_transactions.data);
        }
        
        commit("SET_SHIFT_TRANSACTION_LOADING", false)

        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  getDailyShiftReportTransaction  ( {commit} , payload) {
    return new Promise((resolve, reject) => {
      ShiftServices.getDailyShiftReportTransaction(payload)
      .then(response => {
        commit("SET_SHIFT_TRANSACTION_LOADING", true)
        if (response.data.status_code === 200 ) {
          commit("SET_SELECTED_SHIFT_TRANSACTION", response.data.data);
        } 

        commit("SET_SHIFT_TRANSACTION_LOADING", false)

        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  getShiftReportTransaction ( {commit} , payload) {
    return new Promise((resolve, reject) => {
      ShiftServices.getShiftReportTransaction(payload)
      .then(response => {
        commit("SET_SHIFT_TRANSACTION_LOADING", true)
        if (response.data.status_code === 200) {
          commit("SET_SHIFT_TRANSACTION_ALL", response.data.data);
          commit("SET_SHIFT_TRANSACTION_LOADING", false)
        }
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  exportShiftTransaction (_, payload) {
    return new Promise((resolve, reject) => {
      ShiftServices.exportShiftTransaction(payload)
      .then(response => {
          // if(response.data.status_code === 200) {
          //   commit("END_SHIFT")
          // }
        // if (response.data.status_code === 200) {
        //   commit("SET_CASHIER_SHIFT", response.data.data.);
        // }
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  exportShiftTransactionDaily (_, payload) {
    return new Promise((resolve, reject) => {
      ShiftServices.exportShiftTransactionDaily(payload)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  exportSelectedShiftTransaction (_, payload) {
    return new Promise((resolve, reject) => {
      ShiftServices.exportSelectedShiftTransaction(payload)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }
  
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}