const state = () => ({
  numpadVisibility: false,
  extraValue: 0
})

const mutations = {
  SET_NUMPAD(state){
    state.numpadVisibility = !state.numpadVisibility
  },
  SET_EXTRA_VALUE(state, payload){
    state.extraValue = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations
}