import { apiClientAuth } from "@/config";

export default {
  async getProducts(payload) {
    return apiClientAuth({
      method: "get",
      url: "/api/products",
      ...payload
    });
  },
  async getProduct(payload) {
    return apiClientAuth({
      method: "get",
      url: "/api/products/" + payload,
    });
  },
  async getProductCategory(payload) {
    return apiClientAuth({
      method: "get",
      url: "/api/product-categories",
      ...payload
    });
  },
  async getProductByBarcode(barcode) {
    return apiClientAuth({
      method: "post",
      url: "/api//product/" + barcode
    });
  }
}