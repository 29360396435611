export default [
  {
    path: '/',
    name: 'Dashboard',
    component: () =>
      import(
        '@/pages/index.vue'
      ),
    meta: {
      auth: true,
      title: 'Inti Health Store - POS',
      layout: 'DefaultLayout',
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(
        '@/pages/login.vue'
      ),
    meta: {
      title: 'Inti Health Store - POS',
      layout: 'LoginLayout'
    }
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () =>
      import(
        '@/pages/payment.vue'
      ),
    meta: {
      title: 'Payment - Inti Health Store - POS',
      layout: 'PaymentLayout'
    }
  },
  {
    path: '/order-success',
    name: 'OrderSuccess',
    component: () =>
      import(
        '@/pages/orderSuccess.vue'
      ),
    meta: {
      title: 'Payment - Inti Health Store - POS',
      layout: 'PaymentLayout'
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: () =>
      import(
        '@/pages/report.vue'
      ),
    meta: {
      title: 'Laporan - Inti Health Store - POS',
      layout: 'PaymentLayout'
    }
  },
  {
    path: '/report-all',
    name: 'ReportAll',
    component: () =>
      import(
        '@/pages/report.vue'
      ),
    meta: {
      title: 'Laporan - Inti Health Store - POS',
      layout: 'PaymentLayout'
    }
  },
  {
    path: '/print-report',
    name: 'PrintReport',
    component: () =>
      import(
        '@/pages/printReport.vue'
      ),
    meta: {
      title: 'Report Harian - Inti Health Store - POS',
      layout: 'PaymentLayout'
    }
  },
  {
    path: '/return',
    name: 'Return',
    component: () =>
      import(
        '@/pages/return/index.vue'
      ),
    meta: {
      title: 'Return - Inti Health Store - POS',
    }
  },
  {
    path: '/return/print-receipt',
    name: 'ReturnReceipt',
    component: () =>
    import(
      '@/pages/return/print-receipt/index.vue'
    ),
    meta: {
      title: 'Return Print - Inti Health Store - POS',
    }
  },
  {
    path: '/user-view',
    name: 'UserView',
    component: () =>
      import(
        '@/pages/UserView.vue'
      ),
    meta: {
      title: 'Inti Health Store - POS',
      layout: 'LoginLayout'
    }
  },
]