import { apiClientAuth } from "@/config";

export default {
    async verifyAccessCode(payload) {
        return apiClientAuth({
            method: "post",
            url: "api/cashier/returns/verify",
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : ""
            },
            ...payload
        });
    },
    async searchReturnOrder(payload) {
        return apiClientAuth({
            method: "get",
            url: "api/cashier/returns",
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : ""
            },
            ...payload
        });
    },
    async returnOrder(payload) {
        return apiClientAuth({
            method: "post",
            url: "api/cashier/returns",
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : ""
            },
            ...payload
        });
    },
}