const state = () => ({
  sidebarVisible: true,
  sidebarItem: [
    {
      id: 1,
      name: 'Pembelian',
      icon: require('@/assets/icons/catalog-icon.svg'),
      url: '/'
    },
    {
      id: 2,
      name: 'Laporan',
      icon: require('@/assets/icons/report-icon.svg'),
      url: '/report'
    },
    {
      id: 3,
      name: 'Retur',
      icon: require('@/assets/icons/return-icon.svg'),
      url: '/return'
    },
  ],
  sidebarItemActive: '',
  filterProductItem: [
    {
      id: 1,
      name: 'Terbaru',
      value: 'latest'
    },
    {
      id: 2,
      name: 'Terpopuler',
      value: 'most_popular'
    },
    {
      id: 3,
      name: 'Termurah',
      value: 'cheapest'
    },
    {
      id: 4,
      name: 'Termahal',
      value: 'most_expensive'
    },
  ],
  filterProduct: {}
});

const mutations = {
  setSidebarVisible(state, payload){
    state.sidebarVisible = payload
  },
  setSidebarItemActive(state, payload){
    state.sidebarItemActive = payload
  },
  setFilterProduct(state, payload){
    state.filterProduct = payload
  }
}

const getters = {
  sidebarIcon(state){
    if(state.sidebarItemActive === 'Pembelian'){
      return require('@/assets/icons/catalog-icon_active.svg')
    } else if(state.sidebarItemActive === 'Laporan'){
      return require('@/assets/icons/report-icon-active.svg')
    }
    else if(state.sidebarItemActive === 'Retur'){
      return require('@/assets/icons/return-icon-active.svg')
    }
  },
  filterProductDefault(state){
    if(Object.keys(state.filterProduct).length===0){
      let x = state.filterProductItem.filter((r)=>{
        if(r.id===1){
          return r
        }
      })
      state.filterProduct = x[0]
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
};