import CategoryServices from "@/services/CategoryService";

const state = () => ({
  categories: {},
  categoryLoading: true
})

const mutations = {
  SET_CATEGORIES(state, payload){
    state.categories = payload
    state.categoryLoading = false
  }
}

const actions = {
  getCategories({commit}, payload) {
    return new Promise((resolve, reject) => {
    CategoryServices.getCategories(payload).then(response => {
        if (response.data.status_code === 200) {
          commit("SET_CATEGORIES",response.data.data.categories)
        }
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}