const state = () => ({
  paymentMethodList: [
    {
      id: 1,
      name: 'BCA'
    },
    {
      id: 2,
      name: 'Mandiri'
    },
    {
      id: 3,
      name: 'BRI'
    },
    {
      id: 4,
      name: 'BNI'
    },
    {
      id: 5,
      name: 'CIMB Niaga'
    },
  ],
  paymentStatus: false
});

const mutations = {
  SET_PAYMENT_STATUS(state, payload){
    state.paymentStatus = payload
  }
}

const getters = {
  
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
};