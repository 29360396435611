import { apiClientAuth } from "@/config";

export default {
  async loginCashier(payload) {
    return apiClientAuth({
      method: "post",
      url: "/api/login",
      ...payload
    });
  }
}