import Vue from 'vue'
import VueRouter from 'vue-router'
import VueLazyload from 'vue-lazyload'
import VueSkeletonLoader from 'skeleton-loader-vue'
import VueScrollLoader from 'vue-scroll-loader'
import VueBarcodeScanner from 'vue-barcode-scanner'
import OtpInput from "@bachdgvn/vue-otp-input";
import VueCookies from 'vue-cookies'

import AppLayout from '@/layouts/AppLayout.vue'
import router from '@/routes/index.js'
import store from '@/store'

import ElementUI from "element-ui"
import locale from 'element-ui/lib/locale/lang/en'

import '@/assets/scss/main.scss'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'element-ui/lib/theme-chalk/index.css'

import './registerServiceWorker'

import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')


window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '95368c929787313fee50',
  cluster: 'ap1',
  forceTLS: true
});

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueLazyload)
Vue.use(VueSkeletonLoader)
Vue.use(VueScrollLoader)
Vue.use(require('vue-moment'));
Vue.use(VueBarcodeScanner)
Vue.use(VueCookies)
Vue.use(ElementUI, {locale})
Vue.component("v-otp-input", OtpInput);

new Vue({
    store,
    router,
    render: h => h(AppLayout),
}).$mount('#app')