import { apiClientAuth } from "@/config";

export default {
  async createOrder (payload) {
    return apiClientAuth({
      method: "post",
      url: "/api/cashier/orders",
      ...payload
    });
  },
  
}