import UserServices from "@/services/UserServices";

const state = () => ({
  customerList: [],
  customerSelected: localStorage.getItem('customer-data') ? JSON.parse(localStorage.getItem('customer-data')) : {},
  calculatedPoint: parseInt(localStorage.getItem('calculated-point') || 0),
  shiftStarted: false,
  customerHistory: []
})

const mutations = {
  SET_LIST_CUSTOMER(state, payload) {
    state.customerList = payload
  },
  RESET_LIST_CUSTOMER(state) {
    state.customerList = []
  },
  SET_SELECTED_CUSTOMER(state, payload) {
    localStorage.setItem('customer-data', JSON.stringify(payload))
    state.customerSelected = payload
  },
  SET_CUSTOMER_HISTORY(state, payload) {
    if (payload.current_page === 1) {
      state.customerHistory = payload
    } else {
      state.customerHistory = { ...payload, orders: [...state.customerHistory.orders, ...payload.orders] }
    }
  },
  RESET_SELECTED_CUSTOMER(state) {
    state.customerSelected = {}
    localStorage.removeItem('customer-data')
  },
  SET_CALCULATED_POINT(state, payload) {
    localStorage.setItem('calculated-point', payload)
    state.calculatedPoint = payload
  },
  SET_NEW_CUSTOMER(state, payload) {
    state.newCustomer = payload
  },
  SET_SHIFT_START(state, payload) {
    state.shiftStarted = payload
  }
}

const actions = {
  getCustomers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserServices.getCustomers(payload)
        .then(response => {
          if (response.data.status_code === 200) {
            // if (payload.data.page == 1) {
            //   commit("RESET_LIST_CUSTOMER");
            // }
            commit("SET_LIST_CUSTOMER", response.data.data.user);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  getCustomer({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserServices.getCustomer(payload)
        .then(response => {
          if (response.data.status_code === 200) {
            commit("SET_SELECTED_CUSTOMER", response.data.data.user);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  getCustomerHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserServices.getCustomerHistory(payload)
        .then(response => {
          if (response.data.status_code === 200) {
            commit("SET_CUSTOMER_HISTORY", response.data.data);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  addCustomer({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserServices.addCustomer(payload)
        .then(response => {
          if (response.data.status_code === 201) {
            // if (payload.data.page == 1) {
            //   commit("RESET_LIST_CUSTOMER");
            // }
            commit("SET_NEW_CUSTOMER", response.data.data.user);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}