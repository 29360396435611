import { apiClientAuth } from "@/config";

export default {
  async getCustomers(payload) {
    return apiClientAuth({
      method: "get",
      url: "/api/cashier/users",
      ...payload
    });
  },
  async getCustomer(payload) {
    return apiClientAuth({
      method: "get",
      url: "/api/cashier/users/" + payload.phone_number,
      ...payload
    });
  },
  async getCustomerHistory(payload) {
    return apiClientAuth({
      method: "get",
      url: "/api/cashier/users/" + payload.phone_number + "/history-orders",
      ...payload
    })
  },
  async addCustomer(payload) {
    return apiClientAuth({
      method: "post",
      url: "/api/cashier/users",
      ...payload
    });
  },
}