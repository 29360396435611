import { apiClientAuth } from "@/config";

export default {
    async startShift(payload) {
        return apiClientAuth({
            method: "post",
            url: "/api/cashier/shifts",
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : ""
            },
            ...payload
        });
    },
    async getShift () {
        return apiClientAuth({
            method: "get",
            url: "/api/cashier/shifts/detail/my-active-shift",
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : ""
            },
        })
    },
    async endShift(payload) {
        return apiClientAuth({
            method: "put",
            url: "/api/cashier/shifts/" + payload.data.id,
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : ""
            },
            ...payload
        });
    },
    async createShiftTransaction (payload) {
        return apiClientAuth({
            method: "post",
            url: "/api/cashier/shifts/" + payload.id + "/transactions",
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : ""
            },
            ...payload

        })
    },
    async getShiftListTransaction (payload) {
        return apiClientAuth({
            method: "get",
            url: "/api/cashier/shifts/transactions",
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : ""
            },
            ...payload
        });
    },
    async getShiftReportTransaction (payload) {
        return apiClientAuth({
            method: "get",
            url: "/api/cashier/shifts/transactions/report",
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : ""
            },
            ...payload
        })
    },
    async getDailyShiftReportTransaction () {        
        return apiClientAuth({
            method: "get",
            url: "/api/cashier/shifts/transactions/daily-report",
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : ""
            },
        })  
    },

    async exportShiftTransaction(payload) {
        return apiClientAuth({
            method: "get",
            url: payload,
            responseType: 'blob',
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : "",
                Accept:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });
    },
    async exportShiftTransactionDaily(payload) {
        return apiClientAuth({
            method: "get",
            url: "/api/cashier/export/shifts/" + payload + "/transactions",
            responseType: 'blob',
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : "",
                Accept:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });
    },
    async exportSelectedShiftTransaction(payload) {
        return apiClientAuth({
            method: "get",
            url: "/api/cashier/export/shifts/transactions/" + payload,
            responseType: 'blob',
            headers :{
                Authorization: localStorage.getItem("access-token")
                ? "Bearer " + localStorage.getItem("access-token")
                : "",
                Accept:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });
    }
}